export const trans = (i18n) => {
    const trans = (key, ...args) => {
        const str = i18n[key];
        return str ? format(str, args) : key;
    };
    // see optimisations in project/MessageCompiler.scala
    const resolvePlural = (key, count) => i18n[`${key}:${site.quantity(count)}`] || i18n[`${key}:other`] || i18n[key] || i18n[`${key}:one`];
    trans.pluralSame = (key, count, ...args) => trans.plural(key, count, count, ...args);
    trans.plural = function (key, count, ...args) {
        const str = resolvePlural(key, count);
        return str ? format(str, args) : key;
    };
    // optimisation for translations without arguments
    trans.noarg = (key) => i18n[key] || key;
    trans.vdom = (key, ...args) => {
        const str = i18n[key];
        return str ? list(str, args) : [key];
    };
    trans.vdomPlural = (key, count, ...args) => {
        const str = resolvePlural(key, count);
        return str ? list(str, args) : [key];
    };
    return trans;
};
// for many users, using the islamic calendar is not practical on the internet
// due to international context, so we make sure it's displayed using the gregorian calendar
export const displayLocale = document.documentElement.lang.startsWith('ar-')
    ? 'ar-ly'
    : document.documentElement.lang;
;
export const commonDateFormat = new Intl.DateTimeFormat(displayLocale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
}).format;
export const timeago = (date) => formatAgo((Date.now() - toDate(date).getTime()) / 1000);
// format Date / string / timestamp to Date instance.
export const toDate = (input) => input instanceof Date ? input : new Date(isNaN(input) ? input : parseInt(input));
// format the diff second to *** time ago
export const formatAgo = (seconds) => {
    const absSeconds = Math.abs(seconds);
    const strIndex = seconds < 0 ? 1 : 0;
    const unit = agoUnits.find(unit => absSeconds >= unit[2] * unit[3] && unit[strIndex]);
    return site.trans.pluralSame(unit[strIndex], Math.floor(absSeconds / unit[2]));
};
// past, future, divisor, at least
const agoUnits = [
    ['nbYearsAgo', 'inNbYears', 60 * 60 * 24 * 365, 1],
    ['nbMonthsAgo', 'inNbMonths', (60 * 60 * 24 * 365) / 12, 1],
    ['nbWeeksAgo', 'inNbWeeks', 60 * 60 * 24 * 7, 1],
    ['nbDaysAgo', 'inNbDays', 60 * 60 * 24, 2],
    ['nbHoursAgo', 'inNbHours', 60 * 60, 1],
    ['nbMinutesAgo', 'inNbMinutes', 60, 1],
    [undefined, 'inNbSeconds', 1, 9],
    ['rightNow', 'justNow', 1, 0],
];
function format(str, args) {
    if (args.length) {
        if (str.includes('%s'))
            str = str.replace('%s', args[0]);
        else
            for (let i = 0; i < args.length; i++)
                str = str.replace('%' + (i + 1) + '$s', args[i]);
    }
    return str;
}
function list(str, args) {
    const segments = str.split(/(%(?:\d\$)?s)/g);
    if (args.length) {
        const singlePlaceholder = segments.indexOf('%s');
        if (singlePlaceholder !== -1)
            segments[singlePlaceholder] = args[0];
        else
            for (let i = 0; i < args.length; i++) {
                const placeholder = segments.indexOf('%' + (i + 1) + '$s');
                if (placeholder !== -1)
                    segments[placeholder] = args[i];
            }
    }
    return segments;
}
